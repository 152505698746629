<template>
    <div class="main-register-auth">
        <ui-card class="main-register-auth__card mx-auto mb-5">
            <div class="text-center">
                <router-link to="/login">
                    <img class="w-50" src="https://pay.ecart.com/img/logo-negro.svg" alt="" />
                </router-link>
            </div>
            <form class="main-register-auth__form" @submit.prevent="addApp">
                <application-form ref="applicationForm" />
                <ui-button
                    :loading="saving"
                    class="w-100"
                    type="submit"
                    variant="primary"
                >
                    Send
                </ui-button>
            </form>
        </ui-card>
    </div>
</template>

<script>
import ApplicationForm from '@/components/twofactorauth/ApplicationForm';
import UiButton from '@/components/ui/buttons/Button.vue';
import UiCard from '@/components/ui/Card.vue';

export default {
    components: {
        ApplicationForm,
        UiCard,
        UiButton,
    },
    data(){
        return {
            saving: false,
        }
    },
    mounted() {
        this.$refs.applicationForm.generateOtp()
    },
    methods: {
        async addApp(){
            try {
                this.saving = true;

                if (!await this.$refs.applicationForm.validate()) {
                    return;
                }

                await this.$axios.post('/_/otp/verify', this.$refs.applicationForm.formData);
                
                this.$toast.success('Two-factor authentication successfully enabled');
                this.$router.push('/accounts');
            } catch(error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.saving = false;
            }
        },
    }
}
</script>


<style lang="scss">
.main-register-auth {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    padding: 3rem 0;
    position: relative;

    @media(max-width: 576px) {
        background-color: #ffffff;
        box-shadow: none;
    }

    &__card {
        width: 100%;
        max-width: 480px;
        position: relative;
        z-index: 2;

        @media(max-width: 576px) {
            background-color: transparent;
            box-shadow: none;
        }
    }

    &__form{
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}
</style>